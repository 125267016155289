:root {
  --primary-color: #9334F6;
  --nav-background-color: #FFFFFF;
  --nav-active-background-color: #f5f5f5;
  --nav-active-child-background-color: #fafafa;
  --nav-active-color: #9334F6;
  --nav-in-active-color: rgba(50, 50, 50, 0.7);

  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: Roboto;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: 'poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 3px;
  /* Remove scrollbar space */
  height: 3px;
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #bdc3c7;
}
