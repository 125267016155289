/*
Created by esoda
Created on Nov, 2023
Contact esoda.id
*/

.main_splash {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    min-height: 100vh;
    background-color: transparent;
    background-image: linear-gradient(167.46deg, #f4a749 -8.65%, #a441b8 79.26%);
}

.main_splash_container {
    width: 450px;
    height: calc(100vh - 0vh);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #505050;
    overflow: hidden;
}

.footers {
    position: absolute;
    bottom: 20px;
    color: white;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

/* @media (max-width: 450px) {
    .main_splash_container { width: 100%; height: calc(100vh - 0vh); }
} */

@media (max-width: 899px) {
    .main_splash_container {
        width: 100%;
        height: calc(100vh - 0vh);
    }
}

/* Tablet and Smaller Desktop */
@media (min-width: 900px) and (max-width: 4048px) {
    .main_splash_container {
        width: 500px;
        height: calc(100vh - 0vh);
    }
}