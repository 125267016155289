pre code.hljs {
  display: block;
  overflow-x: auto;
  padding: 1em;
  border-radius: 5px;
}

code.hljs {
  padding: 3px 5px
}

/*

Atom One Dark by Daniel Gamage
Original One Dark Syntax theme from https://github.com/atom/one-dark-syntax

base:    #282c34
mono-1:  #abb2bf
mono-2:  #818896
mono-3:  #5c6370
hue-1:   #56b6c2
hue-2:   #61aeee
hue-3:   #c678dd
hue-4:   #98c379
hue-5:   #e06c75
hue-5-2: #be5046
hue-6:   #d19a66
hue-6-2: #e6c07b

*/
.hljs {
  color: #abb2bf;
  background: #282c34
}

.hljs-comment,
.hljs-quote {
  color: #5c6370;
  font-style: italic
}

.hljs-doctag,
.hljs-keyword,
.hljs-formula {
  color: #c678dd
}

.hljs-section,
.hljs-name,
.hljs-selector-tag,
.hljs-deletion,
.hljs-subst {
  color: #e06c75
}

.hljs-literal {
  color: #56b6c2
}

.hljs-string,
.hljs-regexp,
.hljs-addition,
.hljs-attribute,
.hljs-meta .hljs-string {
  color: #98c379
}

.hljs-attr,
.hljs-variable,
.hljs-template-variable,
.hljs-type,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-number {
  color: #d19a66
}

.hljs-symbol,
.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-title {
  color: #61aeee
}

.hljs-built_in,
.hljs-title.class_,
.hljs-class .hljs-title {
  color: #e6c07b
}

.hljs-emphasis {
  font-style: italic
}

.hljs-strong {
  font-weight: bold
}

.hljs-link {
  text-decoration: underline
}


.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}


.my_modal_container {
  padding: 0 20px !important;
  background-color: #fff;
  width: 460px;
  border-radius: 7.5px;
}

.form_act_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.9;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.form_act_btn:hover {
  opacity: 1.0;
}

.my_modal_detail_container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    border-radius: 7.5px;
    height: 100%;
    overflow: hidden;
}

.my_modal_detail_container_small {
    width: 50%;
    height: auto;
}
.my_modal_detail_container .toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: solid 1px rgba(0,0,0,0.1);
    padding: 0px 20px;
    font-size: 14px;
    height: 60px;
}
.my_modal_detail_container .toolbar .title { font-size: 16px; font-weight: bold; }
.my_modal_detail_container .toolbar .close { cursor: pointer; }
.my_modal_detail_container .toolbar .close svg { color: #e74c3c; }
.my_modal_detail_container .content {
    flex:1; 
    overflow: hidden; 
    overflow-y: scroll; 
    overflow-x: scroll;
}
.my_modal_detail_container .content .flex {
    display: flex;
    flex-direction: row;
    height: 100%;
}
.my_modal_detail_container .content .flex .left { 
    width: 100%;
    overflow: scroll;
    border-right: solid 1px rgba(0, 0, 0, 0.1);
    padding: 20px;
}
.my_modal_detail_container .content .flex .right {
    width: 100%;
    overflow: scroll;
    padding: 20px;
}
.my_modal_detail_container .footer  {
    height: 60px;
    border-top: solid 1px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
}

.label_flex_row { display: flex; flex-direction: row; align-items: center; justify-content: space-between; color: #505050; }
.label_flex_row .left { flex: 1; }
.label_flex_row .label_right { text-align: right; font-weight: bold; }

.my_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #777;
  opacity: 0.95;
  border-radius: 5px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}

.my_btn .title {
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.my_btn:hover {
  opacity: 0.85;
}

.form_act_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.9;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.flex_row_center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ds_flex_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: -20px;
}

.ds_flex_row .box {
  width: 100%;
  height: 120px;
  margin-right: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 7px 0px 0px rgba(0, 0, 0, 0.02);
  overflow: hidden;
  border: solid 1px rgba(0, 0, 0, 0.02);
}

.ds_flex_row .box .ic_ctx {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-bottom-right-radius: 8px;
}

.contents {
  padding-top: 24px;
  width: 100%;
  height: calc(100vh - 55px);
}

.merchant_item {
  cursor: pointer;
  padding: 5px 10px;
}

.merchant_item:hover {
  background-color: rgba(189, 195, 199, 0.3);
}
.active{
  background-color: rgba(189, 195, 199, 1);
}

@media (max-width: 899px) {
  .my_modal_container {
    width: 90%;
  }

  .my_modal_detail_container { width: 100%; border-radius: 0px; height: 100%; }
  .my_modal_detail_container .content .flex { flex-direction: column; overflow: scroll; }
  .my_modal_detail_container .content .flex .left { overflow: visible; }
  .my_modal_detail_container .content .flex .right { overflow: visible; padding-top: 0px; }
  .my_modal_detail_container .footer  {
      justify-content: center;
  }

  .label_flex_row { display: flex; flex-direction: row; align-items: center; justify-content: space-between; color: #505050; }
  .label_flex_row .left { flex: 1; }
  .label_flex_row .label_right { text-align: right; }

  .ds_flex_row {
    flex-direction: column;
    padding: 0 10px;
  }

  .ds_flex_row .box {
    margin-bottom: 18px;
  }

  .contents {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 900px) and (max-width: 4048px) {
  .my_modal_container {
    width: 460px;
  }
}

