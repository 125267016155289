/*
Created by esoda
Created on Nov, 2023
Contact esoda.id
*/
.main {
    padding: 0 24px;
}

.list_drawer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    padding: 15px;
    cursor: pointer;
}

.list_drawer_child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    padding: 18px 10px;
    padding-left: 60px;
    cursor: pointer;
}

.list_drawer .title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.list_drawer:hover {
    background-color: rgba(0, 0, 0, 0.20);
}

.list_drawer_child:hover {
    background-color: rgba(0, 0, 0, 0.20);
}

.list_drawer:hover svg {
    color: #fff;
}

.list_drawer_active {
    background-color: rgba(0, 0, 0, 0.10);
}

.list_drawer_child_active {
    background-color: rgba(0, 0, 0, 0.15);
}

.list_drawer_active svg {
    color: #fff;
}

.list_drawer svg {
    font-size: 24px;
}

.list_drawer p {
    margin-left: 20px;
}

.user_info {
    position: fixed;
    height: 55px;
    background-color: #fff;
    color: #505050;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    padding: 10px 15px;
    border-right: solid 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.user_info .right {
    flex: 1;
    padding-left: 15px;
    font-size: 14px;
}

.user_info svg {
    color: rgba(0, 0, 0, 0.15);
    margin-right: -5px;
}

.user_info:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: #fff;
}

.logout {
    width: inherit;
    position: fixed;
    height: 45px;
    bottom: 0;
    background-color: rgba(29, 33, 124, 1);
    color: #505050;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    padding-left: 15px;
    padding-right: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fff;
    cursor: pointer;
    border-top: solid 1px rgba(0, 0, 0, 0.2);
}

.logout .title {
    flex: 1;
    font-size: 14px;
    padding-left: 20px;
}

.logout svg {
    font-size: 24px;
}

.logout:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.toolbars {
    background-color: #fff;
    height: 55px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: solid 1px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    z-index: 1;
}

.toolbars .menu {
    text-align: left;
    color: #505050;
}

.toolbars .placeholder {
    color: #999;
    font-size: 14px;
}

@media (max-width: 450px) {
    .toolbars {
        background-image: linear-gradient(167.46deg, rgba(147, 52, 246, 1) -8.65%, rgba(29, 33, 124, 0.8) 79.26%);
        border-bottom: none;
        padding-left: 20px;
        padding-right: 20px;
    }

    .toolbars .menu svg {
        color: #fff;
    }

    .toolbars .reload svg {
        color: #fff;
    }

    .toolbars .placeholder {
        flex: 1;
        margin-top: 0px !important;
        color: #fff;
        text-align: center;
        font-size: 16px;
    }

    .main {
        padding: 0px;
    }
}

@media (max-width: 899px) {
    .toolbars {
        background-image: linear-gradient(167.46deg, rgba(147, 52, 246, 1) -8.65%, rgba(29, 33, 124, 0.8) 79.26%);
        border-bottom: none;
        padding-left: 20px;
        padding-right: 20px;
    }

    .toolbars .menu svg {
        color: #fff;
        margin-top: 4px;
    }

    .toolbars .reload svg {
        color: #fff;
        margin-top: 4px;
    }

    .toolbars .placeholder {
        flex: 1;
        margin-top: 0px;
        color: #fff;
        text-align: center;
        font-size: 16px;
    }

    .main {
        padding: 0px;
    }
}

/* Tablet and Smaller Desktop */
@media (min-width: 900px) and (max-width: 4048px) {
    .toolbars {
        left: 280px;
    }

    .toolbars .menu {
        display: none;
    }

    .toolbars .reload {
        display: none;
    }
}