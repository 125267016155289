/*
Created by esoda
Created on Nov, 2023
Contact esoda.id
*/

.table {
    position: absolute;
    display: flex;
    flex-flow: column;
    height: calc(100vh - 98px);
    width: calc(100% - 325px);
    margin-top: 20px;
}

.table .header { flex: 0 1 auto; background-color: #fff; border-bottom: solid 1px #ebebeb; }
.table .header .summary { padding-top:20px; padding-left: 20px; padding-right: 20px; }
.table .header .title { padding-top:20px; padding-left: 20px; padding-right: 20px; font-size: 18px; color: #505050; font-weight: bold; min-width: fit-content; }
.table .header .search {
    display: flex; flex-direction: row; z-index: 1;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 20px;
}
.table .header .search .form {
    background-color: rgb(250, 250, 250);
    border-radius: 5px;
    border: solid 1px rgba(0,0,0,0.1);
    height: 40px;
    width: 100%;
    max-width: 350px;
    display: inline-flex;
    align-items: center;
    padding: 0 12px;
}
.table .header .search .form:focus-within { border: solid 1px var(--primary-color) !important; }
.table .header .search .form .material-icons { font-size: 20px; color: rgba(0,0,0,0.2); margin-right: 10px;}
.table .header .search .form:focus-within .material-icons { color: var(--primary-color) !important; }
.table .header .search .form .input { width: inherit; font-size: 14px; background-color: transparent; border: none; color: #777; padding-right: 10px; }
.table .header .search .form .input::placeholder { color: rgba(0,0,0,0.3); }
.table .header .search .form .input:focus { outline: none; background-color: transparent; }
.table .header .search .filter {
    background-color: #777;
    opacity: 0.8;
    border-radius: 5px;
    height: 40px;
    margin-left: 18px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}
.table .header .search .filter .label { font-size: 14px; }
.table .header .search .filter:hover { opacity: 1.0; }
.table .header .search .filter .material-icons { font-size: 20px; margin-right: 5px; }
.table .header .search .add {
    background-color: #16a085;
    opacity: 0.8;
    border-radius: 5px;
    height: 40px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}
.table .header .search .add .label { font-size: 14px; }
.table .header .search .add:hover { opacity: 1.0; }
.table .header .search .add .material-icons { font-size: 20px; margin-right: 5px; }
.table .header .search .button_list {
    flex: 1;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.table .header .search .margin_button_list {
    margin-left: 15px;
}

.table .header .placeholder { display: flex; flex-direction: row; align-items: center; padding-left: 20px; padding-right: 20px; }
.table .header .placeholder .item {
    /* margin-left: 10px; */
    width: fit-content;
    background-color: #ff7675;
    font-size: 12px;
    color: #fff;
    border-radius: 50px;
    padding: 2px 5px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-bottom: 15px;
    margin-top: -5px;
}
.table .header .placeholder .item .material-icons { font-size: 16px; margin-left: 10px; margin-top: 3px; }
.table .header .placeholder .item:hover { background-color: #888; }

.table .content { flex: 1 1 auto; overflow: hidden; overflow-y: auto; overflow-x: auto; }
.table .content table { width: 100%; border-collapse: collapse; }
.table .content table thead { position: sticky; top: 0; z-index: 1; }
.table .content table thead th { padding: 12px 20px; text-align: left; background-color: #f1f2f6; color: white; font-size: 14px; }
.table .content table thead th .items { min-width: max-content; display: flex; justify-content: center; align-items: center; cursor: pointer; color: rgba(0, 0, 0, 0.4); }
.table .content table thead th .items:hover { color: rgba(0, 0, 0, 0.8); }
.table .content table tbody td { padding: 10px 20px; font-size: 14px; }
.table .content table tbody .fixed_position {
    position: sticky;
    right: 0;
    background-color: #fafafa;
}
.table .content table thead .fixed_position {
    position: sticky;
    right: 0;
    background-color: #f1f2f6;
}
.table .content table tbody tr { border-bottom: solid 1px rgba(0, 0, 0, 0.03); }
.table .content table tbody tr:hover { background-color: #f1f2f6; }
.table .content table tbody tr .checklist_item_td:hover { background-color: #f1f2f6 !important; }
.table .content table .list_item { display: flex; flex-direction: row; align-items: center; justify-content: center; text-align: center; }
.table .content table .list_button { display: flex; flex-direction: row; align-items: center; justify-content: flex-end; }
.table .content table .list_item .title { font-size: 14px; }
.table .content table .list_item .value { width: max-content; font-size: 14px; flex-grow: 1; }

.table .content table .list_item_avatar { font-size: 14px; display: flex; flex-direction: row; align-items: center;}
.table .content table .list_item_avatar .placeholder { flex: 1; padding-left: 15px; padding-top: 2px;}
.table .content table .list_item_avatar .placeholder .value { width: max-content; }

.table .content table .list_item_status { font-size: 14px; }
.table .content table .list_item_status .value { display: flex; flex-direction: row; align-items: center; justify-content: space-between; }
.table .content table .list_item_status .value .label { font-size: 12px; text-transform: uppercase; }
.table .content table .list_item_status .value .switch { margin-left: 20px; }
.table .content table .list_item_link { font-size: 14px; }
.table .content table .list_item_link .value { display: flex; flex-direction: row; align-items: center; justify-content: space-between; }
.table .content table .list_item_link .value .label { width: max-content; font-size: 14px; text-transform: uppercase; }
.table .content table .list_item_link .value .link { cursor: pointer; margin-right: 0px; text-decoration-line: underline; }

.table .content table tr:nth-child(event) {background-color: #fafafa;}
.table .content table tr:nth-child(odd) {background-color: #fff;}

.table .footer { flex: 0 1 50px; display: flex; align-items: center; justify-content: center; border-top: solid 1px #ebebeb; }
.table .footer { height: 50px; display: flex; align-items: center; justify-content: center; border-top: solid 1px #ebebeb; }
.table .footer .placeholder { font-size: 14px; color: #505050; }
.table .footer .pages { display: flex; flex-direction: row; align-items: center; }
.table .footer .pages .button {
    cursor: pointer;
    background-color: #888;
    margin-left: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #fff;
}
.table .footer .pages .button .material-icons { font-size: 20px; }
.table .footer .pages .page {
    cursor: pointer;
    background-color: #999;
    color: #fff;
    margin-left: 10px;
    min-width: 32px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding-right: 7.5px;
    padding-left: 7.5px;
    font-size: 14px;
}
.table .footer .pages .divider { margin-left: 10px; margin-bottom: 7px; }
.table .footer .pages .options .placeholder { margin-left: 20px; margin-right: 5px; }
.table .footer .pages .options .input {
    margin-left: 5px;
    height: 30px;
    max-width: 80px;
    padding-left: 8px;
    border-radius: 5px;
    border: none;
    box-sizing: border-box;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    background-color: #fafafa;
    border: solid 1px rgba(0, 0, 0, 0.1);
    color: #505050;
}
.table .footer .pages .options .input:focus { border: solid 1px rgba(0, 0, 0, 0.2); }
.table .footer .loading { display: flex; flex-direction: row; align-items: center; font-size: 14px; color: #999; }

.table .loadmore { 
    width: 100%;
    font-weight: bold; font-size: 14px; 
    position: fixed;
    bottom: 0;
    cursor: pointer;
    background-color: #fff;
    height: 45px;
    color: #505050;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}
.table .loadmore:hover { background-color: antiquewhite; }

.text_align_left { text-align: left !important; }
.text_align_right { text-align: right !important; }
.empty_container { width: 100%; height: 85%; align-items: center; justify-content: center; display: flex; flex-direction: column; }
.checklist_item_td { width: 45px; position: sticky; left: 0; cursor: pointer; }
.active_td { background-color: #dfe4ea !important; }
.checklist_item_th { width: 45px; position: sticky; left: 0; cursor: pointer; }
.checklist_item_th .material-icons { color: #505050; }
.active_main_grid_header_item { color: rgba(0, 0, 0, 0.8) !important; }

.with_page_tab {
    margin-top: 0px;
    height: calc(100vh - 150px);
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
}

.add_table_class {
    margin-top: 0px;
    height: calc(100vh - 160px);
    border-top: none !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
}

@media (max-width: 899px) {
    .table { margin-top: 0px; width: 100%; height: calc(100vh - 68px); }
    .table .footer { display: none; }

    .table .content table tbody tr { display: flex; flex-direction: column; padding-top: 15px; }
    .table .content table thead { display: none; }
    .table .content table .list_item { justify-content: space-between; border-bottom: solid 1px rgba(0, 0, 0, 0.05); padding-bottom: 10px; padding-top: 10px; }
    .table .content table .list_button { align-items: center; justify-content: flex-end; margin-right: -10px; padding-bottom: 15px; }
    .table .content table .list_item .title { text-align: left; }
    .table .content table .list_item .value { width: auto; text-align: right; display: flex; justify-content: flex-end; align-self: flex-end; font-weight: bold; }
    .table .content table tr:nth-child(event) {background-color: #ffffff; }
    .table .content table tbody td { padding: 0 20px; }
    .empty_container { height: 90%; text-align: center; padding: 0 50px; }
    .checklist_item_td { display: none; }
    .checklist_item_th { display: none; }
    .active_td {background-color: transparent !important; }
    .table .content table .list_item_status { width: 100% !important; display: flex; flex-direction: row; align-items: center; justify-content: space-between; }
    .table .content table .list_item_status .value .switch { margin-left: 10px; margin-right: -12px; }
    .table .content table .list_item_link { width: 100% !important; display: flex; flex-direction: row; align-items: center; justify-content: space-between; border-bottom: solid 1px rgba(0, 0, 0, 0.05); padding-bottom: 10px; padding-top: 10px; }
    .table .content table .list_item_link .value .link { margin-left: 10px; }
    .table .content table .list_item_avatar { padding-bottom: 10px; }

    .table .header .search {  
        padding-top: 0px;
        padding-bottom: 20px;
        width: 100%; 
    }

    .table .header .no_padding { padding: 0px  !important; }
    /* .table .header .placeholder .ad_padding { padding-top: 20px; } */
    .table .header .padding_top20 { padding-top: 20px; }
    
    /* .table .header .summary { display: none; } */
    .table .header .title { display: none; }
    .table .header .search .form { width: inherit; border-radius: 0; height: 45px; max-width: 100%; }
    .table .header .search .form:focus-within { border: solid 1px rgba(0,0,0,0.1); }
    .table .header .search .add { min-width: 55px; border-radius: 0; height: 45px; opacity: 1; }
    .table .header .search .add .label { display: none; }
    .table .header .search .add .material-icons { font-size: 24px; margin-right: 0px; }
    .table .header .search .filter { min-width: 60px; margin-left: 0px; border-radius: 0; height: 45px; opacity: 1;}
    .table .header .search .filter .label { display: none; }
    .table .header .search .filter .material-icons { font-size: 24px; margin-right: 0px; }

    .add_summary_margin_bottom20 { margin-bottom: 20px; }
    .table .header .search .margin_button_list { margin-right: 15px; }

    .with_page_tab {
        margin-top: 40px;
        height: calc(100vh - 108px);
        border-top-right-radius: 0px !important;
        border-top-left-radius: 0px !important;
    }

    .add_table_class {
        margin-top: 50px !important;
        border-radius: 0px !important;
        height: calc(100vh - 118px);
    }

    .hide_row_mobile {
        display: none;
    }
}

/* Tablet and Smaller Desktop */
@media (min-width: 900px) and (max-width: 4048px) {
    .table { background-color: white; border: solid 1px rgba(0, 0, 0, 0.08); border-radius: 10px; overflow: hidden; }
    .table .content { background-color: #fafafa; }
    .table .footer { justify-content: space-between; padding: 15px 20px; }
    .table .loadmore { display: none; }
    .table .content table .list_item .title { display: none; }
    .table .content table thead th { border-right: solid 1px rgba(0, 0, 0, 0.03); }
    .table .content table .list_item_status { text-align: left; }
    .table .content table .list_item_status .title { display: none; }
    .table .content table .list_item_status .value .label { width: max-content; }
    .table .content table .list_item_status .value .switch { margin-right: -15px; }
    .table .content table .list_item_link { text-align: left; }
    .table .content table .list_item_link .title { display: none; }

    .table .header .search .add { padding: 0 12px; min-width: fit-content; margin-left: 18px; }
    .table .header .search .filter { padding: 0 12px; min-width: fit-content; }
}